import React, { useState, useEffect } from 'react';
import {
  Previous,
  Paginator,
  PageGroup,
  Next,
  Container,
} from 'chakra-paginator';
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import {
  Box,
  Card,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function ItemList({ items, curPage, itemLimit, onOpenModal }) {
  const startIndex = (curPage - 1) * itemLimit;
  const endIndex = startIndex + itemLimit;
  const itemsToShow = items.slice(startIndex, endIndex);

  const getImageUrl = img => {
    return `https://api.siesa.com.pa/img/${img}`;
  };

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {itemsToShow.length === 0 ? (
        <Box fontSize="2xl" mb={40} mt={40}>
          Sin proyectos aún
        </Box>
      ) : (
        itemsToShow.map((item, index) => {
          const imagenes = item.imagen.split(',');

          return (
            <Box
              key={index}
              width={{ base: '100%', sm: '50%', md: '25%' }}
              marginBottom={10}
              marginRight={{ base: 0, sm: 4, md: 6 }}
            >
              <Card
                maxWidth={304}
                margin="auto"
                flex="0 0 auto"
                height="100%"
                display="flex"
                flexDirection="column"
                boxShadow="grey -2px 2px 6px"
                borderColor="black"
              >
                {imagenes.length > 1 ? (
                  <Carousel showThumbs={false} style={{ flex: 1 }}>
                    {imagenes.map((img, i) => (
                      <Image
                        key={i}
                        src={getImageUrl(img.trim())}
                        style={{
                          width: '100%',
                          maxHeight: '200px',
                          objectFit: 'cover',
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px',
                        }}
                      />
                    ))}
                  </Carousel>
                ) : (
                  <Image
                    src={getImageUrl(imagenes[0].trim())}
                    style={{
                      width: '100%',
                      maxHeight: '200px',
                      objectFit: 'cover',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }}
                  />
                )}

                <Box
                  padding={6}
                  flexGrow={1}
                  display="flex"
                  flexDirection="column"
                >
                  <Box as="h2" fontWeight="bold" fontSize="xl" mb={2}>
                    {item.nombre}
                  </Box>
                  <Box fontSize="md" color="black" mb={2}>
                    {item.empresa}
                  </Box>
                  <Box fontSize="md" color="black" mb={2}>
                    Estado: {item.estado}
                  </Box>

                  <Button alignItems="center" onClick={() => onOpenModal(item)}>
                    Ver detalles
                  </Button>
                </Box>
              </Card>
            </Box>
          );
        })
      )}
    </Box>
  );
}

function ItemPage() {
  const [items, setItems] = useState([]);
  const itemLimit = 9;
  const [curPage, setCurPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const baseStyles = {
    width: 5,
  };

  const normalStyles = {
    ...baseStyles,
    backgroundColor: 'gray.200',
  };

  const activeStyles = {
    ...baseStyles,
    backgroundColor: '#f7ac23',
  };

  useEffect(() => {
    fetch('https://api.siesa.com.pa/projects')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setItems(data);
        }
      })
      .catch(error => {
        console.error('Error al obtener datos del proyecto:', error);
      });
  }, []);

  const pagesQuantity = Math.ceil(items.length / itemLimit);

  const handlePageChange = page => {
    setCurPage(page);
  };

  const handleOpenModal = item => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const getImageUrl = img => {
    return `https://api.siesa.com.pa/img/${img}`;
  };

  return (
    <Box mt={8}>
      <ItemList
        items={items}
        curPage={curPage}
        itemLimit={itemLimit}
        onOpenModal={handleOpenModal}
      />
      <Paginator
        onPageChange={handlePageChange}
        pagesQuantity={pagesQuantity}
        normalStyles={normalStyles}
        activeStyles={activeStyles}
      >
        <Container justify="center" p={2}>
          <Previous backgroundColor="gray.300" m={2}>
            <CgChevronLeft />
          </Previous>
          <PageGroup isInline align="center" />
          <Next backgroundColor="gray.300" m={2}>
            <CgChevronRight />
          </Next>
        </Container>
      </Paginator>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedItem && selectedItem.nombre}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" flexWrap="wrap">
              {selectedItem && selectedItem.imagen.split(',').length > 1 ? (
                <Box width="100%">
                  <Carousel showThumbs={false}>
                    {selectedItem.imagen.split(',').map((img, i) => (
                      <Image
                        key={i}
                        src={getImageUrl(img.trim())}
                        style={{
                          width: '100%',
                          maxHeight: '500px',
                          objectFit: 'cover',
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px',
                        }}
                      />
                    ))}
                  </Carousel>
                </Box>
              ) : (
                <Box width="100%">
                  <Image
                    src={getImageUrl(
                      selectedItem && selectedItem.imagen.trim()
                    )}
                    style={{
                      width: '100%',
                      maxHeight: '500px',
                      objectFit: 'cover',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }}
                  />
                </Box>
              )}
              <Box flexGrow={1} mt={4}>
                <Box fontSize="md" color="black" mb={2}>
                  Empresa: {selectedItem && selectedItem.empresa}
                </Box>
                <Box fontSize="md" color="black" mb={2}>
                  Descripción: {selectedItem && selectedItem.descripcion}
                </Box>
                <Box fontSize="md" color="black" mb={2}>
                  Estado: {selectedItem && selectedItem.estado}
                </Box>
                <Box fontSize="md" color="black" mb={2}>
                  Ubicación: {selectedItem && selectedItem.ubicacion}
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseModal}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ItemPage;
