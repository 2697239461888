import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export default function Information() {
  return (
    <Box>
      <Text fontSize="2xl" fontWeight="bold" mb={2}>
        ¡Bienvenido al panel administrador de SIESA!
      </Text>
      <Text whiteSpace="pre-line">
        {'\n'}
        {'\n'}
        El panel administrador de SIESA es una herramienta diseñada para
        facilitar la gestión de usuarios, productos y proyectos de manera
        intuitiva y sin necesidad de conocimientos en programación.{'\n'}
        {'\n'} Con nuestro panel, puedes realizar tareas como agregar, editar y
        eliminar proyectos como tambien productos con solo unos pocos clics.
        {'\n'}
        {'\n'} Esto garantiza un control adecuado de la información y la
        seguridad de tu negocio.{'\n'}
        {'\n'} Nos hemos enfocado en simplificar el proceso para que puedas
        concentrarte en lo más importante: hacer crecer tu negocio. Nuestro
        panel te brinda todas las funcionalidades necesarias para administrar
        eficientemente tus proyectos, productos y usuarios.{'\n'}
        {'\n'} Estamos seguros de que disfrutarás de todas las ventajas y
        facilidades que ofrece nuestro panel administrador de SIESA.{'\n'}
        {'\n'} ¡No dudes en explorar todas las funcionalidades y sacar el máximo
        provecho de esta herramienta para potenciar tu negocio!
      </Text>
    </Box>
  );
}
