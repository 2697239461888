import React, { useState, useEffect } from 'react';
import {
  Previous,
  Paginator,
  PageGroup,
  Next,
  Container,
} from 'chakra-paginator';
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import {
  Box,
  Card,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function ItemPageProducts({ categoryProp }) {
  const [items, setItems] = useState([]);
  const itemLimit = 9;
  const [curPage, setCurPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const baseStyles = {
    width: 5,
  };

  const normalStyles = {
    ...baseStyles,
    backgroundColor: 'gray.200',
  };

  const activeStyles = {
    ...baseStyles,
    backgroundColor: '#f7ac23',
  };

  useEffect(() => {
    fetch('https://api.siesa.com.pa/products')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setItems(data);
        }
      })
      .catch(error => {
        console.error('Error al obtener datos de productos:', error);
      });
  }, []);

  const filteredItems = items.filter(item => item.categoria === categoryProp);

  const pagesQuantity = Math.ceil(filteredItems.length / itemLimit);

  const handlePageChange = page => {
    setCurPage(page);
  };

  const handleOpenModal = item => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const getImageUrl = img => {
    return `https://api.siesa.com.pa/img/${img}`;
  };

  const paginatedItems = filteredItems.slice(
    (curPage - 1) * itemLimit,
    curPage * itemLimit
  );

  const maniobra = `Soluciones e Integraciones Eléctricas S.A. Ofrece una amplia gama de
  botoneras o pulsadores, selectores, combinadores y pilotos luminosos que les
  aseguran un control de sus procesos, a su vez permite tomar decisiones y mejorar
  los procesos. Además, ofrecemos una amplia gama de arrancadores de motor
  desde componentes individuales, incluidos relevadores de sobrecarga térmica, de
  sobre corriente y contactores, hasta una solución modular completa que combina
  todas las funciones en un solo producto.`;

  const proteccion = `Soluciones e Integraciones Eléctricas S.A. ofrece componentes individuales
de protección incluidos interruptores, relevadores de sobrecarga térmica de sobre
corriente, brindando las protecciones que más se adapten a sus necesidades.
Dentro de nuestra amplia gama también encontrarán productos diseñados para
proteger los sistemas eléctricos contra daños causados por sobrecargas,
cortocircuitos, sobre tensiones productos internas y externas, productos de
descargas atmosféricas, entre otros.`;

  const iluminacion =
    'SIESA ofrece gran variedad de Luminaria de calidad con un alto respaldo de marcas para los sectores de iluminación Comercial, Industrial y Residencial.\n\nIluminación Comercial / Residencial';

  return (
    <Box mt={8}>
      <Box
        p={4}
        mb={4}
        bg="#f1f1f1"
        borderRadius="md"
        display="inline-block"
        fontSize="3xl"
        fontWeight="bold"
      >
        {categoryProp}
      </Box>

      {categoryProp === 'Equipos de protección' && (
        <Box
          maxW="1200px"
          mx="auto"
          my={6}
          p={6}
          fontSize="lg"
          textAlign="justify"
        >
          {proteccion}
        </Box>
      )}

      {categoryProp === 'Equipo de maniobra y control industrial' && (
        <Box
          maxW="1200px"
          mx="auto"
          my={6}
          p={6}
          fontSize="lg"
          textAlign="justify"
        >
          {maniobra}
        </Box>
      )}

      {categoryProp === 'Iluminación' && (
        <Box
          maxW="1200px"
          mx="auto"
          my={6}
          p={6}
          fontSize="lg"
          textAlign="justify"
        >
          {iluminacion}
        </Box>
      )}

      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {filteredItems.length === 0 ? (
          <Box fontSize="2xl" mb={40} mt={40}>
            Sin productos en esta categoría
          </Box>
        ) : (
          paginatedItems
            .filter(item => item.categoria === categoryProp)
            .map((item, index) => {
              const imagenes = item.imagen.split(',');

              return (
                <Box
                  key={index}
                  width={{ base: '100%', sm: '50%', md: '25%' }}
                  marginBottom={10}
                  marginRight={{ base: 0, sm: 4, md: 6 }}
                >
                  <Card
                    maxWidth={304}
                    margin="auto"
                    flex="0 0 auto"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    boxShadow="grey -2px 2px 6px"
                    borderColor="black"
                  >
                    {imagenes.length > 1 ? (
                      <Carousel showThumbs={false} style={{ flex: 1 }}>
                        {imagenes.map((img, i) => (
                          <Image
                            key={i}
                            src={getImageUrl(img.trim())}
                            style={{
                              width: '100%',
                              maxHeight: '200px',
                              objectFit: 'cover',
                              borderTopLeftRadius: '10px',
                              borderTopRightRadius: '10px',
                            }}
                          />
                        ))}
                      </Carousel>
                    ) : (
                      <Image
                        src={getImageUrl(imagenes[0].trim())}
                        style={{
                          width: '100%',
                          maxHeight: '200px',
                          objectFit: 'cover',
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px',
                        }}
                      />
                    )}

                    <Box
                      bg="#f1f1f1"
                      padding={6}
                      flexGrow={1}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box as="h2" fontWeight="bold" fontSize="xl" mb={2}>
                        {item.nombre}
                      </Box>
                      <Box fontSize="md" color="black" mb={2}>
                        {item.categoria}
                      </Box>

                      <Button
                        alignItems="center"
                        onClick={() => handleOpenModal(item)}
                      >
                        Ver detalles
                      </Button>
                    </Box>
                  </Card>
                </Box>
              );
            })
        )}
      </Box>

      <Paginator
        onPageChange={handlePageChange}
        pagesQuantity={pagesQuantity}
        normalStyles={normalStyles}
        activeStyles={activeStyles}
      >
        <Container justify="center" p={2}>
          <Previous backgroundColor="gray.300" m={2}>
            <CgChevronLeft />
          </Previous>
          <PageGroup isInline align="center" />
          <Next backgroundColor="gray.300" m={2}>
            <CgChevronRight />
          </Next>
        </Container>
      </Paginator>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedItem && selectedItem.nombre}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box display="flex" flexWrap="wrap">
              {selectedItem && selectedItem.imagen.split(',').length > 1 ? (
                <Box width="100%">
                  <Carousel showThumbs={false}>
                    {selectedItem.imagen.split(',').map((img, i) => (
                      <Image
                        key={i}
                        src={getImageUrl(img.trim())}
                        style={{
                          width: '100%',
                          maxHeight: '500px',
                          objectFit: 'cover',
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px',
                        }}
                      />
                    ))}
                  </Carousel>
                </Box>
              ) : (
                <Box width="100%">
                  <Image
                    src={getImageUrl(
                      selectedItem && selectedItem.imagen.trim()
                    )}
                    style={{
                      width: '100%',
                      maxHeight: '500px',
                      objectFit: 'cover',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }}
                  />
                </Box>
              )}
              <Box flexGrow={1} mt={4}>
                <Box fontSize="md" color="black" mb={2}>
                  Descripción: {selectedItem && selectedItem.descripcion}
                </Box>
                <Box fontSize="md" color="black" mb={2}>
                  Categoría: {selectedItem && selectedItem.categoria}
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCloseModal}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ItemPageProducts;
