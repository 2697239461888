import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, AddIcon } from '@chakra-ui/icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function Products() {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [products, setProducts] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProduct, setEditedProduct] = useState({});
  const [newProduct, setNewProduct] = useState({
    nombre: '',
    descripcion: '',
    categoria: '',
    imagen: '',
  });

  const [searchValue, setSearchValue] = useState('');
  const [filterCategory, setFilterCategory] = useState('');

  useEffect(() => {
    fetch('https://api.siesa.com.pa/products')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setProducts(data);
        } else {
          console.log('La respuesta no es un array:', data);
        }
      })
      .catch(error => console.log(error));
  }, [products]);

  const handleAddProduct = () => {
    const formData = new FormData();
    formData.append('nombre', newProduct.nombre);
    formData.append('descripcion', newProduct.descripcion);
    formData.append('categoria', newProduct.categoria);
    for (let i = 0; i < newProduct.imagen.length; i++) {
      formData.append('imagen', newProduct.imagen[i]);
    }

    fetch('https://api.siesa.com.pa/products', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        setProducts([...products, data]);

        setNewProduct({
          nombre: '',
          descripcion: '',
          categoria: '',
          imagen: '',
        });
        setIsAdding(false);
        setSuccessMessage('Producto agregado correctamente.');
      })
      .catch(error => console.log(error));
  };

  const handleEditProduct = () => {
    const { id, nombre, descripcion, categoria, imagen } = editedProduct;

    // Validar campos obligatorios
    if (!nombre || !descripcion || !categoria) {
      setErrorMessage('Por favor, completa todos los campos.');
      return;
    }

    const formData = new FormData();
    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);
    formData.append('categoria', categoria);

    if (imagen) {
      for (let i = 0; i < imagen.length; i++) {
        formData.append('imagen', imagen[i]);
      }
    }

    fetch(`https://api.siesa.com.pa/products/${id}`, {
      method: 'PUT',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        const updatedProducts = products.map(product =>
          product.id === id ? editedProduct : product
        );
        setProducts(updatedProducts);

        setEditedProduct({});
        setIsEditing(false);
        setSuccessMessage('Producto editado correctamente.');
      })
      .catch(error => console.log(error));
  };

  const handleDeleteProduct = async productId => {
    try {
      // Eliminar el producto
      await fetch(`https://api.siesa.com.pa/products/${productId}`, {
        method: 'DELETE',
      });

      // Eliminar el producto de la lista
      const updatedProducts = products.filter(
        product => product.id !== productId
      );
      setProducts(updatedProducts);
      setSuccessMessage('Producto eliminado correctamente.');
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setNewProduct({
      nombre: '',
      descripcion: '',
      categoria: '',
      imagen: '',
    });
    setEditedProduct({});
    setIsAdding(false);
    setIsEditing(false);
  };

  const handleSearch = e => {
    setSearchValue(e.target.value);
  };

  const handleFilter = e => {
    setFilterCategory(e.target.value);
  };

  const filteredProducts = products.filter(product => {
    // Filtrar por nombre
    const nameMatch = product.nombre
      ?.toLowerCase()
      .includes(searchValue?.toLowerCase() ?? '');

    // Filtrar por categoría (si se ha seleccionado una)
    const categoryMatch = filterCategory
      ? product.categoria === filterCategory
      : true;

    return nameMatch && categoryMatch;
  });

  return (
    <Box overflowX="auto">
      {successMessage && (
        <Alert status="success" mb={4}>
          <AlertIcon />
          {successMessage}
        </Alert>
      )}

      <Box display="flex" alignItems="center" mb={4}>
        <FormControl>
          <FormLabel htmlFor="search">
            Buscar por nombre del producto:
          </FormLabel>
          <Input
            id="search"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            placeholder="Ingrese el nombre del producto"
          />
        </FormControl>
        <FormControl ml={4}>
          <FormLabel htmlFor="category">Filtrar por categoría:</FormLabel>
          <Select
            id="category"
            value={filterCategory}
            onChange={handleFilter}
            placeholder="Seleccione una categoría"
          >
            <option value="">Todas las categorías</option>
            <option value="Equipos de protección">Equipos de protección</option>
            <option value="Iluminación">Iluminación</option>
            <option value="Accesorios eléctricos en general">
              Accesorios eléctricos en general
            </option>
            <option value="Conductores de potencia y control">
              Conductores de potencia y control
            </option>
            <option value="Equipo de maniobra y control industrial">
              Equipo de maniobra y control industrial
            </option>
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" alignItems="left" mb={4}>
        <Button
          leftIcon={<AddIcon />}
          onClick={() => {
            setIsAdding(true);
            setIsEditing(false);
          }}
          fontSize="16px"
          fontWeight="bold"
          bg="#f7ac23"
          color="white"
          _hover={{
            bg: '#f3c061',
          }}
        >
          Agregar producto
        </Button>
      </Box>

      <Table variant="striped" colorScheme="black">
        <Thead>
          <Tr>
            <Th>Nombre</Th>
            <Th>Descripción</Th>
            <Th>Categoría</Th>
            <Th>Imagen</Th>
            <Th>Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredProducts.map(product => (
            <Tr key={product.id}>
              <Td>{product.nombre}</Td>
              <Td>{product.descripcion}</Td>
              <Td>{product.categoria}</Td>
              <Td>
                {product.imagen && typeof product.imagen === 'string' ? (
                  <div style={{ width: '100px', position: 'relative' }}>
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      showIndicators={true}
                      infiniteLoop={true}
                      stopOnHover={true}
                      emulateTouch={true}
                      color={'black'}
                      renderArrowPrev={() => {}}
                      renderArrowNext={() => {}}
                    >
                      {Array.from(new Set(product.imagen.split(','))).map(
                        (image, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '70px',
                            }}
                          >
                            <img
                              src={`https://api.siesa.com.pa/img/${image}`}
                              alt="Imagen"
                              style={{ width: '80px', height: '80px' }}
                            />
                          </div>
                        )
                      )}
                    </Carousel>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                        fontSize: 5,
                      }}
                    >
                      <span>{product.imagen.split(',').length}</span>
                    </div>
                  </div>
                ) : (
                  <span>No tiene imagen</span>
                )}
              </Td>

              <Td>
                <IconButton
                  icon={<EditIcon />}
                  aria-label="Editar"
                  onClick={() => {
                    setIsEditing(true);
                    setEditedProduct(product);
                  }}
                />
                <IconButton
                  icon={<DeleteIcon />}
                  aria-label="Eliminar"
                  mr={2}
                  onClick={() => handleDeleteProduct(product.id)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modal para agregar producto */}
      <Modal isOpen={isAdding} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Agregar producto</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {errorMessage && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
            <FormControl isRequired>
              <FormLabel>Nombre</FormLabel>
              <Input
                value={newProduct.nombre}
                onChange={e =>
                  setNewProduct({ ...newProduct, nombre: e.target.value })
                }
                required
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Descripción</FormLabel>
              <Textarea
                value={newProduct.descripcion}
                onChange={e =>
                  setNewProduct({ ...newProduct, descripcion: e.target.value })
                }
                required
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Categoría</FormLabel>
              <Select
                value={newProduct.categoria}
                onChange={e =>
                  setNewProduct({ ...newProduct, categoria: e.target.value })
                }
                required
              >
                <option value="">Selecciona una categoría</option>{' '}
                <option value="Equipos de protección">
                  Equipos de protección
                </option>
                <option value="Iluminación">Iluminación</option>
                <option value="Accesorios eléctricos en general">
                Accesorios eléctricos en general
                </option>
                <option value="Conductores de potencia y control">
                  Conductores de potencia y control
                </option>
                <option value="Equipo de maniobra y control industrial">
                  Equipo de maniobra y control industrial
                </option>
              </Select>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Imagen</FormLabel>
              <Input
                type="file"
                multiple
                onChange={e =>
                  setNewProduct({
                    ...newProduct,
                    imagen: e.target.files,
                  })
                }
                required
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={() => {
                if (
                  newProduct.nombre &&
                  newProduct.descripcion &&
                  newProduct.categoria &&
                  newProduct.imagen
                ) {
                  handleAddProduct();
                } else {
                  setErrorMessage('Por favor, completa todos los campos.');
                }
              }}
              bg="#f7ac23"
              color="white"
              _hover={{
                bg: '#f3c061',
              }}
            >
              Agregar
            </Button>

            <Button onClick={handleCloseModal}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal para editar producto */}
      <Modal isOpen={isEditing} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar producto</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {errorMessage && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
            <FormControl isRequired>
              <FormLabel>Nombre</FormLabel>
              <Input
                value={editedProduct.nombre || ''}
                onChange={e =>
                  setEditedProduct({
                    ...editedProduct,
                    nombre: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Descripción</FormLabel>
              <Textarea
                value={editedProduct.descripcion}
                onChange={e =>
                  setEditedProduct({
                    ...editedProduct,
                    descripcion: e.target.value,
                  })
                }
              />
            </FormControl>

            <FormControl mt={4} isRequired>
              <FormLabel>Categoría</FormLabel>
              <Select
                value={editedProduct.categoria}
                onChange={e =>
                  setEditedProduct({
                    ...editedProduct,
                    categoria: e.target.value,
                  })
                }
              >
                <option value="">Selecciona una categoría</option>
                <option value="Equipos de protección">
                  Equipos de protección
                </option>
                <option value="Iluminación">Iluminación</option>
                <option value="Accesorios eléctricos en general">
                  Accesorios eléctricos en general
                </option>
                <option value="Conductores de potencia y control">
                  Conductores de potencia y control
                </option>
                <option value="Equipo de maniobra y control industrial">
                  Equipo de maniobra y control industrial
                </option>
              </Select>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Imagen</FormLabel>
              <Input
                type="file"
                multiple
                onChange={e => {
                  const existingFiles = Array.from(editedProduct.imagen || []);
                  const newFiles = Array.from(e.target.files || []);
                  setEditedProduct({
                    ...editedProduct,
                    imagen: [...existingFiles, ...newFiles],
                  });
                }}
              />
              {editedProduct.imagen && (
                <span>{editedProduct.imagen[0].name}</span>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={handleEditProduct}
              bg="#f7ac23"
              color="white"
              _hover={{
                bg: '#f3c061',
              }}
            >
              {' '}
              Guardar
            </Button>
            <Button onClick={handleCloseModal}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
