import React from 'react';
import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
  Flex,
  Center,
  Image,
} from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import { MdLocationOn, MdEmail, MdPhone, MdAccessTime } from 'react-icons/md';
import { Link as ReactRouterLink } from 'react-router-dom';
import logoImage from '../img/logo2.png';

const Logo = props => {
  return (
    <Image src={logoImage} alt="Logo" width={160} height={160} {...props} />
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Box bg="#f1f1f1" color={useColorModeValue('black.700', 'black.200')}>
      <Container as={Stack} maxW={'10xl'} py={10}>
        <Center>
          <SimpleGrid
            templateColumns={{ sm: '1fr 1fr', md: '2fr 2fr 2fr' }}
            spacing={8}
          >
            <Stack spacing={4} align="center">
              <Box>
                <Logo color={useColorModeValue('black.700', 'white')} />
              </Box>
              <Text fontSize={'sm'}>
                © 2019, SIESA - Todos los derechos reservados.
              </Text>
              <Stack direction={'row'} spacing={6}>
                <SocialButton
                  label={'Instagram'}
                  href={
                    'https://instagram.com/siesa_pty?igshid=MzRlODBiNWFlZA=='
                  }
                >
                  <FaInstagram />
                </SocialButton>
              </Stack>
            </Stack>

            <Stack align={'center'}>
              <ListHeader>Compañia</ListHeader>
              <ReactRouterLink to={'/about'}>Nosotros</ReactRouterLink>
              <ReactRouterLink to={'/services'}>Servicios</ReactRouterLink>
              <ReactRouterLink to={'/projects'}>Proyectos</ReactRouterLink>
              <ReactRouterLink to={'/contact-us'}>Contáctanos</ReactRouterLink>
            </Stack>

            <Stack align={'center'}>
              <ListHeader>Información</ListHeader>
              <Flex align="center">
                <Box marginRight={2}>
                  <MdLocationOn size={20} />
                </Box>
                <Text>Ciudad de Panamá, Panamá</Text>
              </Flex>
              <Flex align="center">
                <Box marginRight={2}>
                  <MdEmail size={20} />
                </Box>
                <Text>gerenciaventas@siesa.com.pa</Text>
              </Flex>
              <Flex align="center">
                <Box marginRight={2}>
                  <MdPhone size={20} />
                </Box>
                <Text>+507 211-4708 / +507 6822-6048</Text>
              </Flex>
              <Flex align="center" pl={3}>
                <Box marginRight={1}>
                  <MdAccessTime size={20} />
                </Box>
                <Text>
                  lunes a viernes 8:00AM - 5:00PM / sábado 8:00AM - 1:00PM
                </Text>
              </Flex>
            </Stack>
          </SimpleGrid>
        </Center>
      </Container>
    </Box>
  );
}
