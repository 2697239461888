import React from 'react';
import {
  Stack,
  Flex,
  Text,
  VStack,
  useBreakpointValue,
  Heading,
} from '@chakra-ui/react';
import homeImage from '../img/wbann.webp';

export default function Banner() {
  return (
    <Flex
      w={'full'}
      h={'70vh'}
      style={{
        backgroundImage: `url(${homeImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-r, blackAlpha.600, transparent)'}
      >
        <Stack
          maxW={'2xl'}
          align={'center'}
          spacing={8}
          p={6} // Agrega un padding para crear un espacio alrededor del texto
          textShadow="1px 2px 2px rgba(0, 0, 0, 0.8)" // Efecto de sombra para el texto
        >
          <Heading
            as="h2"
            color={'white'}
            fontWeight={700}
            lineHeight={1.2}
            size={useBreakpointValue({ base: 'xl', md: '3xl' })}
          >
            Soluciones Integrales
          </Heading>
          <Text
            as="h3"
            color={'white'}
            fontWeight={600}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}
          >
            Proyectos Eléctricos de baja y media tensión para el sector:
            industrial, comercial y residencial.
          </Text>
        </Stack>
      </VStack>
    </Flex>
  );
}
