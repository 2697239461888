import React from 'react';
import Home from './screens/Home';
import Projects from './screens/Projects';
import ContactUs from './screens/ContactUs';
import AdmIndex from './screens/admin/Index';
import AdmProducts from './screens/admin/Products';
import AdmProjects from './screens/admin/Projects';
import Products from './screens/Products';

import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Index from './screens/admin/Index';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/products" element={<Products />} />
        <Route
          path="/services"
          element={<Home servicesProps="showServices" />}
        />
        <Route path="/about" element={<Home aboutProps="showAbout" />} />
        <Route path="/admin/index" element={<AdmIndex />} />
        <Route path="/admin/products" element={<AdmProducts />} />
        <Route path="/admin/projects" element={<AdmProjects />} />
        <Route
          path="/products/equipo-de-maniobra-y-control-industrial"
          element={
            <Products categoryProp="Equipo de maniobra y control industrial" />
          }
        />
        <Route
          path="/products/equipos-de-proteccion"
          element={<Products categoryProp="Equipos de protección" />}
        />
        <Route
          path="/products/iluminacion"
          element={<Products categoryProp="Iluminación" />}
        />
        <Route
          path="/products/accesorios-electricos-en-general"
          element={<Products categoryProp="Accesorios eléctricos en general" />}
        />
        <Route
          path="/products/conductores-de-potencia-y-control"
          element={
            <Products categoryProp="Conductores de potencia y control" />
          }
        />

        <Route path="*" element={<Index to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
