import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ItemPageProducts from '../components/ItemPageProducts';

function Products({ categoryProp }) {
  
  return (
    <ChakraProvider>
      <Box textAlign="center" fontSize="xl">
        <Header />
        <Navbar />
        <ItemPageProducts categoryProp={categoryProp} />
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

export default Products;
