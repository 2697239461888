import React from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Image,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import ServiImage from '../img/wservi1.webp';
import ServiImage2 from '../img/wservi2.webp';
import ServiImage3 from '../img/wservi3.webp';

function ServiceWrapper({ children, width, height }) {
  return (
    <Box
      width={width}
      height={height}
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      overflow="hidden"
      borderTopLeftRadius="xl"
      borderTopRightRadius="xl"
      borderBottomLeftRadius="xl"
      borderBottomRightRadius="xl"
    >
      {children}
    </Box>
  );
}

export default function Services() {
  return (
    <Box py={12} mx={{ base: '4', md: 'auto' }}>
      <Heading
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
      >
        <Text
          as={'span'}
          position={'relative'}
          _after={{
            content: "''",
            width: 'full',
            height: '30%',
            position: 'absolute',
            bottom: 1,
            left: 0,
            zIndex: -1,
          }}
        >
          Servicios
        </Text>
      </Heading>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, md: 10 }}
        py={10}
      >
        <ServiceWrapper width={{ base: '100%', md: '450px' }} height="auto">
          <Box py={4} px={{ base: '4', md: '12' }}>
            <Image
              src={ServiImage}
              alt="Service Image"
              width="100%"
              maxHeight="200px"
              objectFit="cover"
              borderTopLeftRadius="xl"
              borderTopRightRadius="xl"
              borderBottomLeftRadius="xl"
              borderBottomRightRadius="xl"
            />
            <Text fontWeight="500" fontSize="2xl" pt={10}>
              Sistema de puesta a tierra y protección atmosférica
            </Text>
          </Box>
          <VStack
            bg={useColorModeValue('#f1f1f1', '#f1f1f1')}
            py={4}
            borderBottomRadius="xl"
          >
            <List spacing={2} textAlign="start" px={{ base: '4', md: '12' }}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Medición de resistividad del terreno.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Medición de resistencia del conductor de tierra.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Mantenimiento e instalación de protección atmosférica.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Suministro e instalación de protección de transitorios (DPS).
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Mantenimiento sistema de puesta tierra.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Emisión de Certificaciones.
              </ListItem>
            </List>
          </VStack>
        </ServiceWrapper>

        <ServiceWrapper width={{ base: '100%', md: '450px' }} height="auto">
          <Box py={4} px={{ base: '4', md: '12' }}>
            <Image
              src={ServiImage3}
              alt="Service Image"
              width="100%"
              maxHeight="200px"
              objectFit="cover"
              borderTopLeftRadius="xl"
              borderTopRightRadius="xl"
              borderBottomLeftRadius="xl"
              borderBottomRightRadius="xl"
            />
            <Text fontWeight="500" fontSize="2xl" pt={10}>
              Mantenimiento de transformadores en aceite y seco
            </Text>
          </Box>
          <VStack
            bg={useColorModeValue('#f1f1f1', '#f1f1f1')}
            py={4}
            borderBottomRadius="xl"
          >
            <List spacing={4} textAlign="start" px={{ base: '4', md: '12' }}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Análisis cromatográficos del Aceite.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Pruebas de Factor de Potencia.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Pruebas TTR.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Pruebas de Aislamiento.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Corrección de Fuga.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Filtraje de Aceite.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Pintura y reparación de Fugas.
              </ListItem>
            </List>
          </VStack>
        </ServiceWrapper>

        <ServiceWrapper width={{ base: '100%', md: '450px' }} height="auto">
          <Box py={4} px={{ base: '4', md: '12' }}>
            <Image
              src={ServiImage2}
              alt="Service Image"
              width="100%"
              maxHeight="200px"
              objectFit="cover"
              borderTopLeftRadius="xl"
              borderTopRightRadius="xl"
              borderBottomLeftRadius="xl"
              borderBottomRightRadius="xl"
            />
            <Text fontWeight="500" fontSize="2xl" pt={10}>
              Otros Servicios
            </Text>
          </Box>
          <VStack
            bg={useColorModeValue('#f1f1f1', '#f1f1f1')}
            py={4}
            borderBottomRadius="xl"
          >
            <List spacing={1} textAlign="start" px={{ base: '4', md: '12' }}>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Estudio de calidad de energía a través de analizador de red.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Gestión energética.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Auditorias energéticas.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Monitoreo remoto de la calidad energética en la empresa.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Diseño y Elaboración de Planos Eléctricos.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Digitalización y lectura de Planos a través de Códigos QR.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Ensamblaje de paneles eléctricos.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Ensamblaje de arrancadores con VDF y Softstarter.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Ensamblaje de Transferencias Automáticas.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Ensamblaje de Banco de Condensadores fijos y automáticos.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Mantenimientos Predictivos y correctivos.
              </ListItem>
              <ListItem fontSize="sm">
                <ListIcon as={FaCheckCircle} color="#f7ac23" />
                Termografías.
              </ListItem>
            </List>
          </VStack>
        </ServiceWrapper>
      </Stack>
    </Box>
  );
}
