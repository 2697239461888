import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Popover,
  Link as ChakraLink,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import { BiUser } from 'react-icons/bi';
import LogoMovil from '../img/logoSimple.png';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import Modal from './Modal';

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIconClick = () => {
    setIsModalOpen(true);
  };

  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'black.800')}
        color={useColorModeValue('black.600', 'black')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Image
            src={LogoMovil}
            alt="Logo"
            width={{ base: 150, md: 'auto' }}
            display={{ base: 'block', md: 'none' }}
          />

          <Flex
            display={{ base: 'none', md: 'flex' }}
            ml="auto"
            mr="auto"
            justifyContent="center"
            alignItems="center"
          >
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
          onClick={handleIconClick}
          cursor="pointer"
          sx={{
            bg: isModalOpen ? 'blackAlpha.300' : 'transparent',
            backdropFilter: isModalOpen
              ? 'blur(10px) hue-rotate(90deg)'
              : 'none',
          }}
        >
          <IconButton
            fontSize="xl"
            fontWeight={600}
            variant="link"
            aria-label="Search"
            icon={<BiUser size={25} color={'black'} />}
          />
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('black.600', 'black.200');
  const activeLinkColor = 'white';
  const activeLinkBgColor = '#f7ac23';
  const linkHoverColor = useColorModeValue('black.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'black.800');

  const location = useLocation();

  return (
    <Stack direction="row" spacing={4}>
      {NAV_ITEMS.map(navItem => (
        <Box key={navItem.label} borderRadius="xl" overflow="hidden">
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Flex align="center">
                <ChakraLink
                  as={ReactRouterLink}
                  to={navItem.href ?? '#'}
                  p={4}
                  fontSize="lg"
                  fontWeight={600}
                  color={
                    location.pathname === navItem.href
                      ? activeLinkColor
                      : linkColor
                  }
                  bg={
                    location.pathname === navItem.href
                      ? activeLinkBgColor
                      : 'transparent'
                  }
                  _hover={{
                    textDecoration: 'underline',
                    color: linkHoverColor,
                  }}
                >
                  {navItem.label}
                  {navItem.children && (
                    <Icon
                      as={ChevronDownIcon}
                      color={
                        location.pathname === navItem.href
                          ? activeLinkColor
                          : 'black'
                      }
                      w={5}
                      h={5}
                      ml={2}
                    />
                  )}
                </ChakraLink>
              </Flex>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                rounded="xl"
                minW="sm"
              >
                <Stack>
                  {navItem.children.map(child => (
                    <DesktopSubNav
                      key={child.label}
                      {...child}
                      isActive={location.pathname === child.href}
                    />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel, isActive }) => {
  return (
    <ChakraLink
      as={ReactRouterLink}
      to={href}
      role="group"
      display="block"
      p={2}
      rounded="md"
      bg={isActive ? '#f7ac23' : 'transparent'}
      _hover={{ bg: useColorModeValue('white', 'black.900') }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: '#f7ac23' }}
            fontWeight={500}
            maxW="600px"
            fontSize={15}
            color={isActive ? 'white' : 'inherit'}
          >
            {label}
          </Text>
          <Text fontSize={'sm'} isTruncated>
            {subLabel}
          </Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={isActive ? 'translateX(0)' : 'translateX(-10px)'}
          opacity={isActive ? '100%' : '0'}
          justify={'flex-end'}
          align={'center'}
          flex={1}
        >
          <Icon color={'#f7ac23'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </ChakraLink>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'black.800')}
      p={2}
      display={{ md: 'none' }}
    >
      {NAV_ITEMS.map(navItem => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <ChakraLink as={ReactRouterLink} to={href}>
        {' '}
        <Flex
          py={2}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Text fontWeight={600} color={'black'}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
      </ChakraLink>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={'gray.200'}
          align={'start'}
        >
          {children &&
            children.map(child => (
              <ReactRouterLink key={child.label} py={2} to={child.href}>
                <Text textAlign="left">{child.label}</Text>
              </ReactRouterLink>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'Inicio',
    href: '/',
  },
  {
    label: 'Nosotros',
    href: '/about',
  },
  {
    label: 'Servicios',
    href: '/services',
  },
  {
    label: 'Productos',
    children: [
      {
        label: 'Equipo de maniobra y control industrial',
        subLabel: '',
        href: '/products/equipo-de-maniobra-y-control-industrial',
      },
      {
        label: 'Equipos de protección',
        subLabel: '',
        href: '/products/equipos-de-proteccion',
      },
      {
        label: 'Iluminación',
        subLabel: '',
        href: '/products/iluminacion',
      },

      {
        label: 'Accesorios eléctricos en general',
        subLabel: '',
        href: '/products/accesorios-electricos-en-general',
      },
      {
        label: 'Conductores de potencia y control',
        subLabel: '',
        href: '/products/conductores-de-potencia-y-control',
      },
      {
        label: 'SIESA - QR App',
        subLabel: '',
        href: 'https://almacenamientoweb-siesa.com/',
      },
    ],
  },
  {
    label: 'Proyectos',
    href: '/projects',
  },
  {
    label: 'Contáctanos',
    href: '/contact-us',
  },
];
