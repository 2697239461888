import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, AddIcon } from '@chakra-ui/icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function Projects() {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [projects, setProjects] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const [newProject, setNewProject] = useState({
    empresa: '',
    nombre: '',
    descripcion: '',
    imagen: '',
    estado: '',
    ubicacion: '',
  });

  const [searchValue, setSearchValue] = useState('');
  const [filterState, setFilterState] = useState('');

  useEffect(() => {
    fetch('https://api.siesa.com.pa/projects')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setProjects(data);
        } else {
          console.log('La respuesta no es un array:', data);
        }
      })
      .catch(error => console.log(error));
  }, [projects]);

  const handleAddProject = () => {
    const formData = new FormData();
    formData.append('empresa', newProject.empresa);
    formData.append('nombre', newProject.nombre);
    formData.append('descripcion', newProject.descripcion);

    for (let i = 0; i < newProject.imagen.length; i++) {
      formData.append('imagen', newProject.imagen[i]);
    }

    formData.append('estado', newProject.estado);
    formData.append('ubicacion', newProject.ubicacion);

    fetch('https://api.siesa.com.pa/projects', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        setProjects([...projects, data]);

        setNewProject({
          empresa: '',
          nombre: '',
          descripcion: '',
          imagen: '',
          estado: '',
          ubicacion: '',
        });
        setIsAdding(false);
        setSuccessMessage('Proyecto agregado correctamente.');
      })
      .catch(error => console.log(error));
  };

  const handleEditProject = () => {
    const { id, empresa, nombre, descripcion, imagen, estado, ubicacion } =
      editedProject;

    // Validar campos obligatorios
    if (!empresa || !nombre || !descripcion || !estado || !ubicacion) {
      setErrorMessage('Por favor, completa todos los campos.');
      return;
    }

    const formData = new FormData();
    formData.append('empresa', empresa);
    formData.append('nombre', nombre);
    formData.append('descripcion', descripcion);

    if (imagen) {
      for (let i = 0; i < imagen.length; i++) {
        formData.append('imagen', imagen[i]);
      }
    }

    formData.append('estado', estado);
    formData.append('ubicacion', ubicacion);

    fetch(`https://api.siesa.com.pa/projects/${id}`, {
      method: 'PUT',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        const updatedProjects = projects.map(project =>
          project.id === id ? editedProject : project
        );
        setProjects(updatedProjects);

        setEditedProject({});
        setIsEditing(false);
        setSuccessMessage('Proyecto editado correctamente.');
      })
      .catch(error => console.log(error));
  };

  const handleDeleteProject = async projectId => {
    try {
      // Eliminar el proyecto
      await fetch(`https://api.siesa.com.pa/projects/${projectId}`, {
        method: 'DELETE',
      });

      // Eliminar el proyecto de la lista
      const updatedProjects = projects.filter(
        project => project.id !== projectId
      );
      setProjects(updatedProjects);
      setSuccessMessage('Proyecto eliminado correctamente.');
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setNewProject({
      empresa: '',
      nombre: '',
      descripcion: '',
      imagen: '',
      estado: '',
      ubicacion: '',
    });
    setEditedProject({});
    setIsAdding(false);
    setIsEditing(false);
  };

  const handleSearch = e => {
    setSearchValue(e.target.value);
  };

  const handleFilter = e => {
    setFilterState(e.target.value);
  };

  const filteredProjects = projects.filter(project => {
    // Filtrar por nombre
    const nameMatch = project.nombre
      ?.toLowerCase()
      .includes(searchValue?.toLowerCase() ?? '');

    // Filtrar por estado (si se ha seleccionado una)
    const stateMatch = filterState ? project.estado === filterState : true;

    return nameMatch && stateMatch;
  });

  return (
    <Box overflowX="auto">
      {successMessage && (
        <Alert status="success" mb={4}>
          <AlertIcon />
          {successMessage}
        </Alert>
      )}

      <Box display="flex" alignItems="center" mb={4}>
        <FormControl>
          <FormLabel htmlFor="search">
            Buscar por nombre del proyecto:
          </FormLabel>
          <Input
            id="search"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            placeholder="Ingrese el nombre del proyecto"
          />
        </FormControl>
        <FormControl ml={4}>
          <FormLabel htmlFor="state">Filtrar por estado:</FormLabel>
          <Select
            id="state"
            value={filterState}
            onChange={handleFilter}
            placeholder="Seleccione un estado"
          >
            <option value="">Todas los estados</option>
            <option value="En progreso">En progreso</option>
            <option value="Completado">Completado</option>
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" alignItems="right">
        <Button
          leftIcon={<AddIcon />}
          onClick={() => setIsAdding(true)}
          marginBottom="8px"
          fontSize="16px"
          fontWeight="bold"
          bg="#f7ac23"
          color="white"
          _hover={{
            bg: '#f3c061',
          }}
        >
          Agregar proyecto
        </Button>
      </Box>
      <Table variant="striped" colorScheme="black">
        <Thead>
          <Tr>
            <Th>Empresa</Th>
            <Th>Nombre</Th>
            <Th>Descripción</Th>
            <Th>Imagen</Th>
            <Th>Estado</Th>
            <Th>Ubicación</Th>
            <Th>Acciones</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredProjects.map(project => (
            <Tr key={project.id}>
              <Td>{project.empresa}</Td>
              <Td>{project.nombre}</Td>
              <Td>{project.descripcion}</Td>
              <Td>
                {project.imagen && typeof project.imagen === 'string' ? (
                  <div style={{ width: '100px', position: 'relative' }}>
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      showIndicators={true}
                      infiniteLoop={true}
                      stopOnHover={true}
                      emulateTouch={true}
                      color={'black'}
                      renderArrowPrev={() => {}}
                      renderArrowNext={() => {}}
                    >
                      {Array.from(new Set(project.imagen.split(','))).map(
                        (image, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '70px',
                            }}
                          >
                            <img
                              src={`https://api.siesa.com.pa/img/${image}`}
                              alt="Imagen"
                              style={{ width: '80px', height: '80px' }}
                            />
                          </div>
                        )
                      )}
                    </Carousel>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                        fontSize: 5,
                      }}
                    >
                      <span>{project.imagen.split(',').length}</span>
                    </div>
                  </div>
                ) : (
                  <span>No tiene imagen</span>
                )}
              </Td>
              <Td>{project.estado}</Td>
              <Td>{project.ubicacion}</Td>
              <Td>
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => {
                    setIsEditing(true);
                    setEditedProject(project);
                  }}
                  aria-label="Editar"
                  marginRight="4px"
                />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() => handleDeleteProject(project.id)}
                  aria-label="Eliminar"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isAdding} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Agregar proyecto</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {errorMessage && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Empresa</FormLabel>
              <Input
                placeholder="Empresa"
                value={newProject.empresa}
                onChange={e =>
                  setNewProject({ ...newProject, empresa: e.target.value })
                }
              />
            </FormControl>
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Nombre</FormLabel>
              <Input
                placeholder="Nombre"
                value={newProject.nombre}
                onChange={e =>
                  setNewProject({ ...newProject, nombre: e.target.value })
                }
              />
            </FormControl>
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Descripción</FormLabel>
              <Textarea
                placeholder="Descripción"
                value={newProject.descripcion}
                onChange={e =>
                  setNewProject({ ...newProject, descripcion: e.target.value })
                }
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Imagen</FormLabel>
              <Input
                type="file"
                multiple
                onChange={e =>
                  setNewProject({
                    ...newProject,
                    imagen: Array.from(e.target.files),
                  })
                }
              />
            </FormControl>
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Estado</FormLabel>
              <Select
                placeholder="Seleccionar estado"
                value={newProject.estado}
                onChange={e =>
                  setNewProject({ ...newProject, estado: e.target.value })
                }
              >
                <option value="En progreso">En progreso</option>
                <option value="Completado">Completado</option>
              </Select>
            </FormControl>
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Ubicación</FormLabel>
              <Input
                placeholder="Ubicación"
                value={newProject.ubicacion}
                onChange={e =>
                  setNewProject({ ...newProject, ubicacion: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="#f7ac23"
              color="white"
              _hover={{
                bg: '#f3c061',
              }}
              mr={3}
              onClick={() => {
                if (
                  newProject.empresa &&
                  newProject.nombre &&
                  newProject.descripcion &&
                  newProject.imagen &&
                  newProject.estado &&
                  newProject.ubicacion
                ) {
                  handleAddProject();
                } else {
                  setErrorMessage('Por favor, completa todos los campos.');
                }
              }}
            >
              Agregar
            </Button>
            <Button onClick={handleCloseModal}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/*  */}
      <Modal isOpen={isEditing} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar proyecto</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {errorMessage && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Empresa</FormLabel>
              <Input
                placeholder="Empresa"
                value={editedProject.empresa}
                onChange={e =>
                  setEditedProject({
                    ...editedProject,
                    empresa: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Nombre</FormLabel>
              <Input
                placeholder="Nombre"
                value={editedProject.nombre}
                onChange={e =>
                  setEditedProject({
                    ...editedProject,
                    nombre: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Descripción</FormLabel>
              <Textarea
                placeholder="Descripción"
                value={editedProject.descripcion}
                onChange={e =>
                  setEditedProject({
                    ...editedProject,
                    descripcion: e.target.value,
                  })
                }
              />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Imagen</FormLabel>
              <Input
                type="file"
                multiple
                onChange={e =>
                  setEditedProject({
                    ...editedProject,
                    imagen: Array.from(e.target.files),
                  })
                }
              />
            </FormControl>
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Estado</FormLabel>
              <Select
                placeholder="Seleccionar estado"
                value={editedProject.estado}
                onChange={e =>
                  setEditedProject({
                    ...editedProject,
                    estado: e.target.value,
                  })
                }
              >
                <option value="En progreso">En progreso</option>
                <option value="Completado">Completado</option>
              </Select>
            </FormControl>
            <FormControl marginBottom="4px" isRequired>
              <FormLabel>Ubicación</FormLabel>
              <Input
                placeholder="Ubicación"
                value={editedProject.ubicacion}
                onChange={e =>
                  setEditedProject({
                    ...editedProject,
                    ubicacion: e.target.value,
                  })
                }
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              bg="#f7ac23"
              color="white"
              _hover={{
                bg: '#f3c061',
              }}
              mr={3}
              onClick={handleEditProject}
            >
              Guardar
            </Button>
            <Button onClick={handleCloseModal}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
