import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChakraProvider,
  Flex,
  Stack,
  Image,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import axios from 'axios';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import logo from '../img/logoSimple.png';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginError, setLoginError] = useState('');
  /*   const [rememberMe, setRememberMe] = useState(false); */

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const theme = {
    labels: {
      email: 'Correo electrónico:',
      password: 'Contraseña:',
      rememberMe: 'Recordarme',
      forgotPassword: '¿Olvidaste tu contraseña?',
      signInButton: 'Iniciar sesión',
    },
  };

  const navigate = useNavigate();

  const handleEmailChange = event => {
    const { value } = event.target;
    setEmail(value);
    setEmailError('');
  };

  const handlePasswordChange = event => {
    const { value } = event.target;
    setPassword(value);
    setPasswordError('');
  };

  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(
        'Por favor, ingresa un correo electrónico válido "ejemplo@ejemplo.com"'
      );
    }
  };

  /* const handleForgotPassword = () => {
    const formData = new FormData();
    formData.append('email', email);

    axios
      .post('https://api.siesa.com.pa/forgot-password', formData)
      .then(res => {
        if (res.status === 200) {
          console.log('Correo electrónico enviado exitosamente');
        } else {
          console.log('Error al enviar el correo electrónico');
        }
      })
      .catch(err => {
        console.error('Error al enviar el correo electrónico:', err);
      });
  }; */

   const handleSubmit = event => {
    event.preventDefault();

    let hasError = false;

    if (email.trim() === '') {
      setEmailError('El campo de correo electrónico es obligatorio.');
      hasError = true;
    }

    if (password.trim() === '') {
      setPasswordError('El campo de contraseña es obligatorio.');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    axios
      .post('https://api.siesa.com.pa/login', { email: email, password: password })
      .then(res => {
        if (res.status === 200 && res.data.token) {
          const token = res.data.token;
          navigate(`/admin/index?loggedIn=true&token=${token}`);
        } else {
          setLoginError('El correo electrónico o contraseña son incorrectos.');
        }
      })
      .catch(err => {
        console.log(err);
        setLoginError('Fallo al conectar con el servidor.');
      });
  }; 


  /*   const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  }; */

  return (
    <ChakraProvider>
      <Flex minH="30vh" align="center" justify="center" px={4}>
        <Stack spacing={4} w="100%" maxW="md" mx={{ base: 4, sm: 0 }}>
          <Image src={logo} alt="Logo" w={64} mx="auto" mt={4} mb={6} />

          <form onSubmit={handleSubmit}>
            <FormControl id="email" isInvalid={!!emailError}>
              <FormLabel>{theme.labels.email}</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
              />
              {emailError && (
                <Alert status="error" mt={2}>
                  <AlertIcon />
                  {emailError}
                </Alert>
              )}
            </FormControl>

            <FormControl id="password" isInvalid={!!passwordError}>
              <FormLabel>{theme.labels.password}</FormLabel>
              <Flex align="center">
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    pr="4.5rem"
                    paddingRight={showPassword ? '2.5rem' : '0.75rem'}
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <ViewOffIcon
                        h={5}
                        w={5}
                        onClick={handleTogglePassword}
                        cursor="pointer"
                      />
                    ) : (
                      <ViewIcon
                        h={5}
                        w={5}
                        onClick={handleTogglePassword}
                        cursor="pointer"
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Flex>
              {passwordError && (
                <Alert status="error" mt={2}>
                  <AlertIcon />
                  {passwordError}
                </Alert>
              )}
            </FormControl>

            <Stack spacing={6}>
              {/* <Stack
                direction={{ base: 'column', sm: 'row' }}
                align="start"
                justify="space-between"
              >
                <Checkbox checked={rememberMe} onChange={handleRememberMe}>
                  {theme.labels.rememberMe}
                </Checkbox>
                <Link color="blue.500" onClick={handleForgotPassword}>
                  {theme.labels.forgotPassword}
                </Link>
              </Stack> */}

              <Button
                type="submit"
                bg="#f7ac23"
                color="white"
                _hover={{
                  bg: '#f3c061',
                }}
                mt={4}
              >
                {theme.labels.signInButton}
              </Button>
            </Stack>

            {loginError && (
              <Alert status="error" mt={4}>
                <AlertIcon />
                {loginError}
              </Alert>
            )}
          </form>
        </Stack>
      </Flex>
    </ChakraProvider>
  );
};

export default Login;
