import React from 'react';
import { Container, Stack, Flex, Heading, Text, Image } from '@chakra-ui/react';
import logoImage from '../img/logo2.png';

export default function About() {
  return (
    <Container maxW={'7xl'}>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }} padding={5}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
          >
            <Text
              as={'span'}
              position={'relative'}
              _after={{
                content: "''",
                width: 'full',
                height: '30%',
                position: 'absolute',
                bottom: 1,
                left: 0,
                zIndex: -1,
              }}
            >
              Sobre Nosotros
            </Text>
          </Heading>
          <Text color={'black.500'} textAlign="justify">
            Somos una empresa fundada en el año 2019, que poco a poco hemos
            venido creciendo.
            <br />
            <br />
            SOLUCIONES E INTEGRACIONES ELÉCTRICAS SA, basa su éxito en ofrecer a
            sus clientes un servicio personalizado y de calidad, como seña de
            identidad y diferenciación dentro de su sector.
            <br />
            <br />
            Ofrecemos productos, servicios y soluciones innovadoras, rentables y
            eficientes que se ajustan a las necesidades y requerimientos de
            nuestros clientes.
            <br />
            <br />
            Estamos integrados por un equipo de profesionales multidisciplinario
            que conforma los diferentes departamentos: Ventas, Proyectos,
            Logística y Administración, que hacen posibles el logro de los
            objetivos de la organización.
            <br />
            <br />
            Nuestra Misión como organización dirigida a la comercialización de
            materiales y equipos eléctricos a nivel de industria, comercio y
            construcción, es proveer soluciones efectivas y eficaces, de
            excelente calidad brindando a nuestros clientes la confianza en que
            su actividad será beneficiada por soluciones integrales y de valor
            agregado.
          </Text>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}
          ></Stack>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
        >
          <Image
            alt={'Logo'}
            fit={'cover'}
            align={'center'}
            w={'100%'}
            h={'100%'}
            src={logoImage}
          />
        </Flex>
      </Stack>
    </Container>
  );
}
