import React, { useState } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  Button,
  HStack,
} from '@chakra-ui/react';
import { FiHome, FiTruck, FiTrendingUp, FiMenu, FiUser } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import Information from '../screens/admin/Information';
import Users from '../screens/admin/Users';
import Products from '../screens/admin/Products';
import Projects from '../screens/admin/Projects';

const LinkItems = [
  { name: 'Inicio', icon: FiHome, component: <Information /> },
  { name: 'Usuarios', icon: FiUser, component: <Users /> },
  { name: 'Productos', icon: FiTruck, component: <Products /> },
  { name: 'Proyectos', icon: FiTrendingUp, component: <Projects /> },
];

export default function Sidebar({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState('');

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/', { replace: true });
  };

  const handleLinkClick = component => {
    setActiveComponent(component);
  };

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => setIsOpen(false)}
        handleLinkClick={handleLinkClick}
        display={{ base: 'none', md: 'block' }}
        activeComponent={activeComponent}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={() => setIsOpen(false)}
        returnFocusOnClose={false}
        onOverlayClick={() => setIsOpen(false)}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={() => setIsOpen(false)}
            handleLinkClick={handleLinkClick}
            activeComponent={activeComponent}
          />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={() => setIsOpen(true)} handleLogout={handleLogout} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
        {activeComponent}
      </Box>
    </Box>
  );
}

function SidebarContent({
  onClose,
  handleLinkClick,
  activeComponent,
  ...rest
}) {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          SIESA ADMIN
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map(link => (
        <NavItem
          key={link.name}
          icon={link.icon}
          onClick={() => handleLinkClick(link.component)}
          isActive={activeComponent === link.component}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
}

function NavItem({ icon, children, onClick, isActive, ...rest }) {
  return (
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      bg={isActive ? '#f7ac23' : undefined}
      color={isActive ? 'white' : undefined}
      _hover={{
        bg: isActive ? '#f7ac23' : '#f3c061',
        color: isActive ? 'white' : undefined,
      }}
      onClick={onClick}
      {...rest}
    >
      {icon && (
        <Box
          mr="4"
          fontSize="16"
          _groupHover={{
            color: 'white',
          }}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
}

function MobileNav({ onOpen, handleLogout, ...rest }) {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Text
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
        display={{ base: 'flex', md: 'none' }}
      >
        SIESA
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Button
            variant="solid"
            bg="#f7ac23"
            color="white"
            _hover={{
              bg: '#f3c061',
            }}
            onClick={handleLogout}
          >
            Cerrar sesión
          </Button>
        </Flex>
      </HStack>
    </Flex>
  );
}
