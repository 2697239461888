import React, { useState, useEffect } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

export default function Users() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch('https://api.siesa.com.pa/users')
      .then(response => response.json())
      .then(data => {
        setUsers(data);
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <Box overflowX="auto">
      <Table variant="striped" colorScheme="black">
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>Contraseña</Th>
            <Th>Nombre</Th>
            <Th>Apellido</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map(user => (
            <Tr key={user.id}>
              <Td>{user.email}</Td>
              <Td>{user.password}</Td>
              <Td>{user.first_name}</Td>
              <Td>{user.last_name}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
