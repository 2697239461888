import React from 'react';
import {
  Button,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useMediaQuery,
} from '@chakra-ui/react';
import Login from './Login';

export default function CustomModal({ isOpen, onClose }) {
  const [isSmallerThanMd] = useMediaQuery('(max-width: 768px)');

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered={isSmallerThanMd}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Login />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cerrar</Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
