import React, { useState } from 'react';
import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  ChakraProvider,
  Alert,
  AlertIcon,
  Stack,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';
import { MdPhone, MdEmail, MdLocationOn } from 'react-icons/md';
import { BsPerson, BsEnvelope } from 'react-icons/bs';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function CompExample({ onClose }) {
  return (
    <Alert status="success">
      <AlertIcon />
      <Box>
        <AlertTitle>¡Éxito!</AlertTitle>
        <AlertDescription>
          Tu mensaje ha sido enviado. Revisaremos tu mensaje y te responderemos
          en las próximas 48 horas.
        </AlertDescription>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
      />
    </Alert>
  );
}

export default function ContactMe() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showInvalidEmailMessage, setShowInvalidEmailMessage] = useState(false);
  const [showRequiredFieldsMessage, setShowRequiredFieldsMessage] =
    useState(false);

  const isEmailValid = email => {
    // Expresión regular para validar el formato del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    // Validar campos requeridos
    if (!name || !email || !message) {
      setShowRequiredFieldsMessage(true);
      setShowSuccessMessage(false);
      setShowInvalidEmailMessage(false);
      return;
    }

    // Validar correo electrónico
    if (!isEmailValid(email)) {
      setShowInvalidEmailMessage(true);
      setShowSuccessMessage(false);
      setShowRequiredFieldsMessage(false);
      return;
    }

    const emailData = {
      name: name,
      email: email,
      message: message,
    };

    try {
      const response = await fetch('https://api.siesa.com.pa/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        setName('');
        setEmail('');
        setMessage('');
        setShowSuccessMessage(true);
        setShowInvalidEmailMessage(false);
        setShowRequiredFieldsMessage(false);
      } else {
        setShowSuccessMessage(false);
        setShowInvalidEmailMessage(false);
        setShowRequiredFieldsMessage(true);
      }
    } catch (error) {
      console.error('Error al enviar el correo electrónico:', error);
      setShowSuccessMessage(false);
      setShowInvalidEmailMessage(false);
      setShowRequiredFieldsMessage(true);
    }
  };

  return (
    <ChakraProvider>
      <Header />
      <Navbar />
      <>
        <Container
          bg="white"
          maxW="full"
          mt={{ base: 4, md: 0 }}
          centerContent
          overflow="hidden"
        >
          <Flex>
            <Box
              bg="#f1f1f1"
              color="white"
              borderRadius="lg"
              m={{ base: 4, sm: 4, md: 16, lg: 10 }}
              p={{ sm: 5, md: 5, lg: 16 }}
            >
              <Box p={4}>
                <Wrap
                  spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}
                  color="black"
                >
                  <WrapItem>
                    <Box textAlign="center">
                      <Heading>Contáctanos</Heading>
                      <Text mt={{ sm: 3, md: 3, lg: 5 }} color="black">
                        Rellene el siguiente formulario para contáctarnos
                      </Text>
                      <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                        <VStack spacing={3} alignItems="center">
                          <Button
                            size="md"
                            height="48px"
                            width="250px"
                            variant="ghost"
                            color="black"
                            leftIcon={<MdPhone color="#f7ac23" size="20px" />}
                          >
                            +507 6822-6048
                          </Button>
                          <Button
                            size="md"
                            height="48px"
                            width="250px"
                            variant="ghost"
                            color="black"
                            leftIcon={<MdEmail color="#f7ac23" size="20px" />}
                          >
                            gerenciaventas@siesa.com.pa
                          </Button>
                          <Button
                            size="md"
                            height="48px"
                            width="250px"
                            variant="ghost"
                            color="black"
                            leftIcon={
                              <MdLocationOn color="#f7ac23" size="20px" />
                            }
                          >
                            Ciudad de Panamá
                          </Button>
                        </VStack>
                      </Box>
                    </Box>
                  </WrapItem>

                  <WrapItem>
                    <Box bg="white" borderRadius="lg" borderWidth="1px">
                      <Box m={8} color="#0B0E3F">
                        <VStack spacing={5}>
                          {showInvalidEmailMessage && (
                            <Stack spacing={3}>
                              <Alert status="error">
                                <AlertIcon />
                                El correo electrónico no es válido
                              </Alert>
                            </Stack>
                          )}

                          {showRequiredFieldsMessage && (
                            <Stack spacing={3}>
                              <Alert status="error">
                                <AlertIcon />
                                Todos los campos son obligatorios
                              </Alert>
                            </Stack>
                          )}

                          {showSuccessMessage ? (
                            <CompExample
                              onClose={() => setShowSuccessMessage(false)}
                            />
                          ) : (
                            <FormControl id="form">
                              <FormControl id="name">
                                <FormLabel>Nombre</FormLabel>
                                <InputGroup borderColor="black">
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={<BsPerson color="black.800" />}
                                  />
                                  <Input
                                    placeholder="Nombre completo"
                                    type="text"
                                    size="md"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                  />
                                </InputGroup>
                              </FormControl>
                              <FormControl id="email">
                                <FormLabel>Email</FormLabel>
                                <InputGroup borderColor="black">
                                  <InputLeftElement
                                    pointerEvents="none"
                                    children={<BsEnvelope color="black.800" />}
                                  />
                                  <Input
                                    placeholder="Correo electrónico"
                                    type="email"
                                    size="md"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                  />
                                </InputGroup>
                              </FormControl>
                              <FormControl id="message">
                                <FormLabel>Mensaje</FormLabel>
                                <Textarea
                                  placeholder="Escribe lo que quieres decirnos"
                                  size="md"
                                  value={message}
                                  onChange={e => setMessage(e.target.value)}
                                />
                              </FormControl>
                              <FormControl id="submit" float="right" mt={4}>
                                <Button
                                  variant="solid"
                                  bg="#f7ac23"
                                  color="white"
                                  _hover={{
                                    bg: '#f3c061',
                                  }}
                                  onClick={handleSubmit}
                                >
                                  Enviar Mensaje
                                </Button>
                              </FormControl>
                            </FormControl>
                          )}
                        </VStack>
                      </Box>
                    </Box>
                  </WrapItem>
                </Wrap>
              </Box>
            </Box>
          </Flex>
        </Container>
      </>
      <Footer />
    </ChakraProvider>
  );
}
