import React from 'react';
import { Heading, Text, Box, Image } from '@chakra-ui/react';
import Carousel from 'react-grid-carousel';
import ScheneiderImage from '../img/logo/wscheneider.webp';
import SiemensImage from '../img/logo/wsiemens.webp';
import EatonImage from '../img/logo/weaton.webp';
import GEImage from '../img/logo/wge.webp';
import HubbellImage from '../img/logo/whubbell.webp';
import LevitonImage from '../img/logo/wleviton.webp';
import RockwellAutomationImage from '../img/logo/wra.webp';
import SylvaniaLightingImage from '../img/logo/wsylvana.webp';
import ThreeMImage from '../img/logo/w3m.webp';
import ABBImage from '../img/logo/wabb.webp';
import CircutorImage from '../img/logo/wcircutor.webp';
import ElastImage from '../img/logo/welast.webp';
import LegrandImage from '../img/logo/wlegran.webp';
import MiguelezImage from '../img/logo/wmige.webp';
import SquaredImage from '../img/logo/wsquared.webp';
import TomasImage from '../img/logo/wtomas.webp';
import NventImage from '../img/logo/wvent.webp';

const LogoSlider = () => {
  const handleRedirect = url => {
    window.open(url, '_blank');
  };

  return (
    <Box pr={20} pl={20} pb={30}>
      <Heading
        lineHeight={1.1}
        fontWeight={600}
        fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
        pb={10}
      >
        <Text
          as={'span'}
          position={'relative'}
          _after={{
            content: "''",
            width: 'full',
            height: '30%',
            position: 'absolute',
            bottom: 1,
            left: 0,
            zIndex: -1,
          }}
        >
          Marcas que comercializamos
        </Text>
      </Heading>
      <Carousel cols={5} rows={1} gap={10} loop>
        <Carousel.Item>
          <button onClick={() => handleRedirect('https://www.se.com/pa/es/')}>
            <Image src={ScheneiderImage} alt="Schneider Electric" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button
            onClick={() =>
              handleRedirect('https://www.siemens.com/global/en.html')
            }
          >
            <Image src={SiemensImage} alt="Siemens" />
          </button>
        </Carousel.Item>

        <Carousel.Item>
          <button
            onClick={() => handleRedirect('https://www.eaton.com/country.html')}
          >
            <Image src={EatonImage} alt="Eaton" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button onClick={() => handleRedirect('https://www.ge.com/latam/')}>
            <Image src={GEImage} alt="GE" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button onClick={() => handleRedirect('https://www.hubbell.com/')}>
            <Image src={HubbellImage} alt="Hubbell" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button
            onClick={() =>
              handleRedirect('https://www.leviton.com/es/homepage')
            }
          >
            <Image src={LevitonImage} alt="Leviton" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button
            onClick={() =>
              handleRedirect('https://www.rockwellautomation.com/es-co.html')
            }
          >
            <Image src={RockwellAutomationImage} alt="Rockwell Automation" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button
            onClick={() =>
              handleRedirect(
                'https://www.sylvania-lighting.com/offices/panama/'
              )
            }
          >
            <Image src={SylvaniaLightingImage} alt="Sylvania Lighting" />
          </button>
        </Carousel.Item>

        <Carousel.Item>
          <button
            onClick={() =>
              handleRedirect('https://www.3m.com.pa/3M/es_PA/inicio/')
            }
          >
            <Image src={ThreeMImage} alt="3M" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button
            onClick={() => handleRedirect('https://new.abb.com/south-america')}
          >
            <Image src={ABBImage} alt="ABB" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button onClick={() => handleRedirect('https://circutor.com/')}>
            <Image src={CircutorImage} alt="Circutor" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button onClick={() => handleRedirect('#')}>
            <Image src={ElastImage} alt="Elast" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button onClick={() => handleRedirect('https://www.legrand.es/')}>
            <Image src={LegrandImage} alt="Legrand" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button onClick={() => handleRedirect('https://www.miguelez.com/pa')}>
            <Image src={MiguelezImage} alt="Miguelez" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button onClick={() => handleRedirect('#')}>
            <Image src={SquaredImage} alt="Squared" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button onClick={() => handleRedirect('#')}>
            <Image src={TomasImage} alt="Tomas" />
          </button>
        </Carousel.Item>
        <Carousel.Item>
          <button
            onClick={() => handleRedirect('https://www.nvent.com/es-pa/')}
          >
            <Image src={NventImage} alt="Nvent" />
          </button>
        </Carousel.Item>
      </Carousel>
    </Box>
  );
};

export default LogoSlider;
