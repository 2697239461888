import React, { useRef, useEffect, useState } from 'react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import About from '../components/About';
import Services from '../components/Services';
import LogoSlider from '../components/LogoSlider';
import Footer from '../components/Footer';
import {
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import scanGif from '../img/scan.gif';
import print from '../img/print.jpg';
import logoSiesaPro from '../img/logoSIESA2.png';

const Home = ({ aboutProps, servicesProps }) => {
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (aboutProps === 'showAbout') {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (servicesProps === 'showServices') {
      servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [aboutProps, servicesProps]);

  useEffect(() => {
    const openTimeout = setTimeout(() => {
      setIsOpen(true); // Abrir el modal a los 2 segundos
    }, 1500);

    const closeTimeout = setTimeout(() => {
      setIsOpen(false); // Cerrar el modal a los 10 segundos
    }, 18000);

    return () => {
      clearTimeout(openTimeout);
      clearTimeout(closeTimeout);
    };
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ChakraProvider>
      <Box textAlign="center" fontSize="xl">
        <Header />
        <Navbar />
        <Banner />
        <div ref={aboutRef}></div>
        <About />
        <div ref={servicesRef}></div>
        <Services />
        <LogoSlider />
        <Footer />

        {/* MODAL DE QR SIESA */}
        <Modal isOpen={isOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent maxW="90rem">
            <ModalCloseButton />
            <ModalBody>
              <Box
                px={{
                  base: '0',
                  lg: '12',
                }}
                py={{
                  base: '6',
                  lg: '12',
                }}
              >
                <Stack
                  direction={{
                    base: 'column-reverse',
                    lg: 'row',
                  }}
                  spacing={{
                    base: '150',
                    lg: '20',
                  }}
                >
                  <Box
                    width={{
                      lg: 'sm',
                    }}
                    transform={{
                      base: 'translateY(-50%)',
                      lg: 'none',
                    }}
                    bg={{
                      base: useColorModeValue('gray.50', 'gray.700'),
                      lg: 'transparent',
                    }}
                    mx={{
                      base: '6',
                      md: '8',
                      lg: '0',
                    }}
                    px={{
                      base: '6',
                      md: '8',
                      lg: '0',
                    }}
                    py={{
                      base: '6',
                      md: '8',
                      lg: '12',
                    }}
                  >
                    <Stack
                      spacing={{
                        base: '8',
                        lg: '10',
                      }}
                      py={{
                        base: '4',
                        lg: '0',
                      }}
                    >
                      <Stack
                        spacing={{
                          base: '4',
                          lg: '4',
                        }}
                        mt={{
                          base: '10',
                          lg: '0',
                        }}
                        align={{
                          base: 'center',
                          lg: 'flex-start',
                        }}
                        textAlign={{
                          base: 'center',
                          lg: 'left',
                        }}
                      >
                        <Image
                          src={logoSiesaPro}
                          alt="SIESA"
                          maxW="200px"
                          maxH="100px"
                        />
                        <Heading
                          size="sm"
                          fontWeight="normal"
                          textAlign="justify"
                        >
                          En la construcción y la ingeniería, la correcta
                          gestión de planos y la colaboración efectiva entre
                          equipos son fundamentales para el éxito de cualquier
                          proyecto; Siesa App es una innovadora aplicación web
                          diseñada para la gestión eficiente de planos y la
                          colaboración sin complicaciones.
                          <br />
                          <br />
                          1. Almacenamiento Centralizado de Planos: Siesa App te
                          permite guardar todos tus planos de proyectos en un
                          único lugar accesible desde cualquier dispositivo con
                          conexión a Internet. Olvídate de los engorrosos
                          archivadores físicos o los servidores locales
                          desorganizados.
                          <br />
                          <br />
                          2. Acceso Rápido a Través de QR: Imagina la comodidad
                          de acceder a tus planos en el sitio de construcción o
                          en una reunión de proyecto con solo escanear un código
                          QR. Siesa App te brinda esta funcionalidad para una
                          experiencia sin complicaciones.
                        </Heading>
                        <Button
                          bg="#f7ac23"
                          color={'white'}
                          mt="4"
                          as="a"
                          href="https://almacenamientoweb-siesa.com/"
                          target="_blank"
                        >
                          Visitar
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>

                  <Flex flex="1" overflow="hidden" mt={20}>
                    <Image
                      src={scanGif}
                      alt="Scann"
                      maxH="450px"
                      minW="300px"
                      objectFit="cover"
                      flex="1"
                    />
                    <Image
                      display={{
                        base: 'none',
                        sm: 'initial',
                      }}
                      src={print}
                      alt="Print"
                      maxH="450px"
                      objectFit="cover"
                    />
                  </Flex>
                </Stack>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </ChakraProvider>
  );
};

export default Home;
