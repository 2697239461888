import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Text, Flex, ChakraProvider, Box } from '@chakra-ui/react';
import Sidebar from '../../components/Sidebar';

export default function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/'); // Redirecciona si no se proporciona un token
    }
  }, [navigate, token]);

  const redirectToHome = () => {
    navigate('/');
  };

  if (!token) {
    const colorCode = '#f1f1f1';

    return (
      <ChakraProvider>
        <Box textAlign="center" fontSize="xl">
          <Flex
            h="100vh"
            justifyContent="center"
            alignItems="center"
            bgColor={colorCode}
            flexDirection="column"
          >
            <Text color="black" fontSize="xl" textAlign="center" mb={4}>
              Por favor, inicia sesión para acceder a esta página.
            </Text>
            <Button
              onClick={redirectToHome}
              variant="solid"
              bg="#f7ac23"
              color="white"
              _hover={{
                bg: '#f3c061',
              }}
            >
              Ir al Inicio
            </Button>
          </Flex>
        </Box>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider>
      <Box textAlign="center" fontSize="xl">
        <Sidebar isLoggedIn={true} />{' '}
        {/* isLoggedIn se establece en true ya que hay un token */}
      </Box>
    </ChakraProvider>
  );
}
