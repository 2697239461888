import React from 'react';
import { Box, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import LogoSiesa from '../img/logo1.png';

const Header = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <React.Fragment>
      {!isMobile && (
        <Flex
          align="center"
          justify="center"
          p={4}
          bg="#f1f1f1"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Box>
            <Image src={LogoSiesa} alt="Logo" maxW="100px" />
          </Box>

          <Text
            fontSize={{ base: '32px', md: '60px' }}
            fontWeight="bold"
            marginLeft="20px"
          >
            Soluciones e Integraciones Eléctricas, S.A.
          </Text>
        </Flex>
      )}
    </React.Fragment>
  );
};

export default Header;
