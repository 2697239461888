import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ItemPage from '../components/ItemPage';

const Projects = () => {
  return (
    <ChakraProvider>
      <Box textAlign="center" fontSize="xl">
        <Header />
        <Navbar />
        <ItemPage />
        <Footer />
      </Box>
    </ChakraProvider>
  );
};

export default Projects;
